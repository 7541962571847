<template>
    <article
        :class="['card', card.post_network_id === $simpleStore.Networks.DY ? 'dc' : '', showMenu ? 'menu-active' : '', !card.post_thumbnail ? 'card__no-media': '']"
        :data-did="card.dyadey_id"
        data-cy="card"
    >
        <div class="card__inner">
            <!-- TODO: BE Draft post -->
            <div class="card__draft" v-if="card.post_is_draft">
                <p v-if="card.draft_only === 1">Not yet posted</p>
                <p v-if="card.draft_only === 0">Version not posted</p>
                <a :href="'/post/edit/' + card.post_id">Edit post</a>
            </div>
            <div v-if="card.post_network_id === $simpleStore.Networks.DY && card.post_thumbnail && card.post_is_draft === false"
                 :class="[
                        'card__media',
                        imageDimsClass,
                        card.post_type === 'video' ? 'video' : '',
                    ]"
                 :data-card-type="card.post_type"
            >
                <a :href="'/' + card.community_url + '/' + card.dyadey_id">
                    <img
                        v-if="card.post_type === 'video'"
                        :src="videoPlayIcon"
                        class="play-icon"
                        alt="video will play from article link"
                        width="34"
                        height="39"
                    >
                    <img v-if="card.post_thumbnail" :src="card.post_thumbnail" :alt="card.post_title">
                </a>
            </div>
            <header>
                <!--
                <div v-if="card.post_network_id == $simpleStore.Networks.YT" class="c-video-wrapper" :data-url="'/' + card.community_url + '/' + card.post_id">
                    <div :id="'player-' + card.post_video_id" class="community-player" :data-vid="card.post_video_id"></div>
                </div>
                -->
                <div class="card__logo">
                    <a
                        v-if="
                            card.post_network_id === $simpleStore.Networks.DY &&
                            card.post_show_community_img !== 1 &&
                            typeof $root.community != 'undefined' &&
                            $root.community.id > 0
                        "
                        :href="'/u/' + card.user_id"
                    >
                        <img
                            v-if="card.community_id > 0"
                            :src="
                                $simpleStore.cloudinaryUrl(
                                    42,
                                    42,
                                    'img/communities/' +
                                        card.community_id +
                                        '/' +
                                        card.community_thumbnail,
                                    $root
                                )
                            "
                            :alt="card.community_name"
                            class="logo"
                        >
                        <img :src="card.user_avatar" :alt="card.cAlt" width="24" height="24" class="ugc__avatar">
                    </a>
                    <a
                        v-else
                        class="cards__default-community-icon"
                        :href="'/' + card.community_url"
                    >
                        <img
                            v-if="card.community_id > 0 && parseInt(card.community_id) !== $root.placeholderCommunityId"
                            :src="
                                $simpleStore.cloudinaryUrl(
                                    42,
                                    42,
                                    'img/communities/' +
                                        card.community_id +
                                        '/' +
                                        card.community_thumbnail,
                                    $root
                                )
                            "
                            :alt="card.community_name"
                            width="42"
                            height="42"
                            class="logo"
                        >
                        <img
                            v-else
                            src="/img/icons/icon-communities-squared--contained.svg"
                            width="42"
                            height="42"
                            alt="Default Community Image"
                        >
                    </a>
                </div>
                <div :class="['card__comm-name', card.post_network_id === $simpleStore.Networks.DY ? 'dc' : '']">
                    <div v-if="card.post_parent_id > 0">{{
                        card.post_reposted_from
                    }}</div>
                    <div v-else>
                        <template v-if="card.post_network_id === $simpleStore.Networks.DY">
                            <strong>{{ card.user_forename }} {{ card.user_surname }}</strong> posted<br>
                            in <strong><a :href="'/' + card.community_url">{{ card.community_name }}</a></strong>
                        </template>
                        <template v-else-if="card.community_id !== $root.placeholderCommunityId">
                            <strong><a :href="'/' + card.community_url">{{ card.community_name }}</a></strong>
                        </template>
                        <template v-else>
                            <strong>{{ card.community_name }}</strong>
                        </template>
                    </div>
                    <time
                        :datetime="card.post_birthday_short"
                        :title="'posted on ' + card.post_birthday_full"
                        >{{ card.post_birthday_long }}</time
                    >
                </div>
                <div class="card__menu" @click="toggleMenu">
                    <div class="dots">
                        <div class="dot"></div>
                    </div>
                </div>
                <Menu :show-menu="showMenu" :close-event="closePostcardMenuEvent">
                    <div>
                        <div class="menu__links" v-if="$route.name !== 'userPosts' && card.post_is_draft === false">
                            <a :href="'/' + card.community_url + '/' + card.dyadey_id">View post</a>
                            <a :href="'/' + card.community_url">View community</a>
                            <a v-if="typeof $root.communities == 'undefined' || $root.communities.includes(card.community_id) === false" href="#" @click.prevent="join">Join community</a>
                            <CopyLink :link="'/' + card.community_url + '/' + card.dyadey_id" menu-name-event="bus-postcard-menu-toggle"></CopyLink>
                            <a href="#">Repost to a community</a>
                        </div>
                        <div class="menu__links" v-else-if="$route.name === 'userPosts' && card.post_is_draft === false">
                            <a :href="'/post/edit/' + card.post_id">Edit post</a>
                            <a :href="'/' + card.community_url + '/' + card.dyadey_id">View post</a>
                            <a :href="'/' + card.community_url">View community</a>
                        </div>
                        <div class="menu__links" v-else-if="card.post_is_draft === true">
                            <a :href="'/post/edit/' + card.post_id">Edit draft</a>
                            <a href="#" @click.prevent="modalDeleteDraft(card.post_id)">Delete draft</a>
                            <a href="#" @click.prevent="toggleMenu">Cancel</a>
                        </div>

                        <div class="menu__source" v-if="$route.name !== 'userPosts' && card.post_is_draft === false">
                            <p>View original source</p>
                            <div class="menu__socials">
                                <div class="menu__social" v-if="card.post_network_id === $simpleStore.Networks.FB">
                                    <a :href="card.post_uri" target="_blank">
                                        <img src="/img2.0/icons/facebook.svg" alt="facebook" width="25" height="25">
                                    </a>
                                </div>
                                <div class="menu__social" v-if="card.post_network_id === $simpleStore.Networks.IN">
                                    <a :href="card.post_source" target="_blank">
                                        <img src="/img2.0/icons/instagram.svg" alt="instagram" width="25" height="25">
                                    </a>
                                </div>
                                <div class="menu__social" v-if="card.post_network_id === $simpleStore.Networks.YT">
                                    <a :href="card.post_uri" target="_blank">
                                        <img src="/img2.0/icons/youtube.svg" alt="facebook" width="25" height="25">
                                    </a>
                                </div>
                                <div class="menu__social" v-if="card.post_network_id === $simpleStore.Networks.TW">
                                    <a :href="card.post_uri" target="_blank">
                                        <img src="/img2.0/icons/twitter.svg" alt="facebook" width="25" height="25"></a>
                                </div>
                                <template v-for="dupe in card.children">
                                    <div class="menu__social" v-if="dupe.network_id === $simpleStore.Networks.FB">
                                        <a :href="dupe.uri" target="_blank">
                                            <img src="/img2.0/icons/instagram.svg" alt="instagram" width="25" height="25">
                                        </a>
                                    </div>
                                    <div class="menu__social" v-if="dupe.network_id === $simpleStore.Networks.IN">
                                        <a :href="dupe.source" target="_blank">
                                            <img src="/img2.0/icons/instagram.svg" alt="instagram" width="25" height="25">
                                        </a>
                                    </div>
                                    <div class="menu__social" v-if="dupe.network_id === $simpleStore.Networks.YT">
                                        <a :href="dupe.uri" target="_blank">
                                            <img src="/img2.0/icons/youtube.svg" alt="facebook" width="25" height="25">
                                        </a>
                                    </div>
                                    <div class="menu__social" v-if="dupe.network_id === $simpleStore.Networks.TW">
                                        <a :href="dupe.uri" target="_blank">
                                            <img src="/img2.0/icons/twitter.svg" alt="facebook" width="25" height="25">
                                        </a>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </Menu>
            </header>
            <template
                data-i="1"
                v-if="
                    card.post_network_id === $simpleStore.Networks.DY &&
                    card.post_is_draft === false
                "
            >
                <p class="title"><strong v-html="card.post_title"></strong></p>
                <div class="card-ctas-wrap">
                    <div class="card-ctas" v-if="card.post_is_draft !== true">
                        <button
                            data-cy="like-post"
                            class="card__likes"
                            @click.prevent="like(false, card)"
                            :class="{ liked: postLiked > 0 }"
                        >
                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.728 19.8376C11.29 19.5766 1 13.3538 1 6.76474C1 3.58612 3.62739 1 6.85673 1C9.02896 1 10.9907 2.18834 12 4.00725C13.0093 2.18834 14.971 1 17.1433 1C20.3726 1 23 3.58612 23 6.76474C23 13.3538 12.71 19.5755 12.272 19.8376L12 20L11.728 19.8376Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>{{ card.likes }}</span>
                        </button>
                        <template v-if="comments !== false">
                            <button
                                class="card__comments"
                                @click="goTo('/' + card.community_url + '/' + card.dyadey_id + '#comments')"
                            >
                                <img src="/img2.0/icons/comment.svg" width="20" height="19" alt="comments">
                                <span>{{ card.comments }}</span>
                            </button>
                        </template>
                    </div>
                    <div class="slider-nav"></div>
<!--                    <div
                        v-if="card.repost_id > 0"
                        class="u-flex u-flex-align-start"
                    >
                        <small
                        ><span class="c-card__postedin"
                        ><a :href="'/u/' + card.username"
                        >{{ card.user_forename }}
                                    {{ card.user_surname }}</a
                        ></span
                        >reposted from</small
                        >
                    </div>
                    <div
                        v-else-if="
                            card.post_is_draft === true &&
                            card.community_id > 1
                        "
                        class="u-flex u-flex-align-start"
                    >
                        <small
                        ><span class="c-card__postedin">Draft</span> posting
                            in</small
                        >
                    </div>
                    <div
                        v-else
                        class="u-flex u-flex-align-start"
                    >
                        <small
                        ><span class="c-card__postedin"
                        ><a :href="'/u/' + card.username"
                        >{{ card.user_forename }}
                                    {{ card.user_surname }}</a
                        ></span
                        >posted in xxx</small
                        >
                    </div>-->
                </div>
                <div class="card__repost">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                        <path d="M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M14 1L11 4L14 7" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 15L12 12L9 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p>Repost</p>
                </div>
            </template>
            <template
                data-i="2"
                v-else-if="
                    card.post_network_id !== $simpleStore.Networks.IN &&
                    card.post_network_id !== $simpleStore.Networks.YT &&
                    !card.post_thumbnail &&
                    card.post_is_draft === false
                "
                class="card__content__wrap card__no-media"
            >
                <template
                    v-if="
                        card.post_title != null &&
                        card.post_content.indexOf(card.post_title) !== 0 &&
                        card.post_title !== 'Timeline Photos' &&
                        card.post_title.indexOf(
                            'This content isn\'t available'
                        ) === -1
                    "
                >
                    <a :href="'/' + card.community_url + '/' + card.dyadey_id">
                        <p
                            class="title"
                            v-if="
                                card.post_title &&
                                card.post_network_id !== $simpleStore.Networks.TW
                            "
                        >
                            <strong v-html="card.post_title"></strong>
                        </p>
                    </a>
                </template>
                <a :href="'/' + card.community_url + '/' + card.dyadey_id">
                    <div
                        class="card__content"
                        v-if="
                            card.post_content &&
                            card.post_network_id !== $simpleStore.Networks.YT
                        "
                        v-html="
                            $simpleStore.highlightHashtags(
                                card.post_content,
                                card.community_url
                            )
                        "
                        v-twitteremoji
                    ></div>
                </a>
                <template v-if="embeddedTweet">
                    <div class="card__tweet">
                        <div class="content">
                            <div class="profile">
                                <img
                                    :src="embeddedTweet.profile_image"
                                    alt="profile image"
                                >
                                <a :href="embeddedTweet.link" target="_blank"
                                    >@{{ embeddedTweet.user }}</a
                                >
                                &#183;
                                {{ embeddedTweet.created }}
                            </div>
                            <p v-html="embeddedTweet.text" v-twitteremoji></p>
                        </div>
                        <a
                            v-if="embeddedTweet.media != null"
                            :href="embeddedTweet.link"
                            target="_blank"
                            >
                            <img :src="embeddedTweet.media.url" alt="" class="media">
                        </a>
                    </div>
                </template>
                <div class="card-ctas-wrap">
                    <div class="card-ctas" v-if="card.post_is_draft !== true">
                        <button
                            data-cy="like-post"
                            class="card__likes"
                            @click.prevent="like(false, card)"
                            :class="{ liked: postLiked > 0 }"
                        >
                            <img src="/img2.0/icons/like.svg" width="22" height="19" alt="like">
                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.728 19.8376C11.29 19.5766 1 13.3538 1 6.76474C1 3.58612 3.62739 1 6.85673 1C9.02896 1 10.9907 2.18834 12 4.00725C13.0093 2.18834 14.971 1 17.1433 1C20.3726 1 23 3.58612 23 6.76474C23 13.3538 12.71 19.5755 12.272 19.8376L12 20L11.728 19.8376Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>{{ card.likes }}</span>
                        </button>
                        <button
                            class="card__comments"
                            @click="goTo('/' + card.community_url + '/' + card.dyadey_id + '#comments')"
                        >
                            <img src="/img2.0/icons/comment.svg" width="20" height="19" alt="comments">
                            <span>{{ card.comments }}</span>
                        </button>
                    </div>
                    <div class="slider-nav"></div>
                    <div
                        v-if="card.repost_id > 0"
                        class="u-flex u-flex-align-start"
                    >
                        <small>
                            <span class="card__postedin">
                                <a :href="'/u/' + card.username">{{ card.user_forename }} {{ card.user_surname }}</a>
                            </span>
                            reposted from
                        </small>
                    </div>
                    <div
                        v-else-if="
                            card.post_network_id === 7 &&
                            card.post_is_draft === true &&
                            card.community_id > 1
                        "
                        class="u-flex u-flex-align-start"
                    >
                        <small>
                            <span class="card__postedin">Draft</span>
                            posting in
                        </small>
                    </div>
                    <div
                        v-else-if="card.post_is_draft === true"
                        class="u-flex u-flex-align-start"
                    >
                        <small>
                            <span class="card__postedin">Draft Post</span>
                        </small>
                    </div>
                    <div
                        v-else-if="card.post_network_id === 7"
                        class="u-flex u-flex-align-start"
                    >
                        <small>
                            <span class="card__postedin">
                                <a :href="'/u/' + card.username">{{ card.user_forename }} {{ card.user_surname }}</a>
                            </span>
                            posted in
                        </small>
                    </div>
                    <div v-else class="card__social-from">
                        <img v-if="card.post_network_id === $simpleStore.Networks.FB"
                            src="/img2.0/icons/facebook.svg" alt="facebook" width="22" height="22"
                        >
                        <img v-if="card.post_network_id === $simpleStore.Networks.TW"
                            src="/img2.0/icons/twitter.svg" alt="twitter" width="22" height="22"
                        >
                        <template v-for="dupe in card.children">
                            <img
                                v-if="dupe.network_id === $simpleStore.Networks.FB"
                                src="/img2.0/icons/facebook.svg" alt="facebook" width="22" height="22"
                            >
                            <img
                                v-if="dupe.network_id === $simpleStore.Networks.TW"
                                src="/img2.0/icons/twitter.svg" alt="twitter" width="22" height="22"
                            >
                        </template>
                    </div>
                </div>
            </template>
            <div
                data-i="3"
                v-else-if="card.post_is_draft === false"
                class="card__content__wrap"
            >
                <a :href="'/' + card.community_url + '/' + card.dyadey_id">
                    <div
                        :class="[
                            'card__media',
                            imageDimsClass,
                            card.post_type.indexOf('video') !== -1 ? 'video' : '',
                        ]"
                        :data-card-type="card.post_type"
                    >
                        <img
                            v-if="
                                card.post_network_id !== $simpleStore.Networks.IN &&
                                card.post_thumbnail
                            "
                            :src="getPostImage(card.post_thumbnail)"
                            :alt="card.post_title"
                            @error="(event) => updatePost(event, card)"
                            v-bind="postImageDimensions"
                        >
                        <img
                            v-if="
                                card.post_network_id === $simpleStore.Networks.IN &&
                                card.post_type !== 'video' &&
                                card.post_thumbnail
                            "
                            :src="getPostImage(card.post_thumbnail)"
                            :alt="card.community_name"
                            @error="(event) => updatePost(event, card)"
                            v-bind="postImageDimensions"
                        >
                        <img
                            v-if="
                                card.post_network_id === $simpleStore.Networks.IN &&
                                card.post_type === 'video'
                            "
                            :src="
                                card.post_thumbnail == null ||
                                card.post_thumbnail.indexOf('http') === -1
                                    ? 'https://dyadey.com' + card.post_thumbnail
                                    : card.post_thumbnail
                            "
                            :alt="card.community_name"
                            @error="(event) => updatePost(event, card)"
                            v-bind="postImageDimensions"
                        >
                        <img
                            v-if="card.post_type.indexOf('video') !== -1"
                            :src="videoPlayIcon" class="play-icon" alt="video will play from article link" width="34" height="39"
                        >
                        <div class="slider-arrows" v-if="card.post_has_slides">
                            <img src="/img2.0/icons/slide-arrow.svg" width="36px" height="36px" alt="slide arrow" class="prev">
                            <img src="/img2.0/icons/slide-arrow.svg" width="36px" height="36px" alt="slide arrow" class="next">
                        </div>
                        <div class="slider-nav">
                            <img src="/img2.0/icons/slide.svg" width="6" height="2" alt="slide active">
                            <img src="/img2.0/icons/slide-active.svg" width="6" height="2" alt="slide active">
                        </div>
                    </div>
                </a>
                <div class="card-ctas-wrap">
                    <div class="card-ctas" v-if="card.post_is_draft !== true">
                        <button
                            data-cy="like-post"
                            class="card__likes"
                            @click="like(false, card)"
                            :class="{ liked: postLiked > 0 }"
                        >
                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.728 19.8376C11.29 19.5766 1 13.3538 1 6.76474C1 3.58612 3.62739 1 6.85673 1C9.02896 1 10.9907 2.18834 12 4.00725C13.0093 2.18834 14.971 1 17.1433 1C20.3726 1 23 3.58612 23 6.76474C23 13.3538 12.71 19.5755 12.272 19.8376L12 20L11.728 19.8376Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>{{ card.likes }}</span>
                        </button>
                        <button
                            class="card__comments"
                            @click="goTo('/' + card.community_url + '/' + card.dyadey_id + '#comments')"
                        >
                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 20L19.4891 14.4021C20.4468 12.339 20.6181 10.0081 19.9719 7.83187C19.3256 5.65568 17.9047 3.77825 15.9665 2.53997C14.0284 1.30168 11.7014 0.784484 9.40743 1.08212C7.11341 1.37975 5.00419 2.47254 3.46214 4.16235C1.92008 5.85216 1.04725 8.02716 1.00186 10.2931C0.956479 12.559 1.74155 14.7659 3.21475 16.5136C4.68796 18.2614 6.7518 19.4343 9.03214 19.8198C11.3125 20.2053 13.6584 19.7779 15.6446 18.615L21 20Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>{{ card.comments }}</span>
                        </button>
                    </div>
                    <div
                        v-if="card.repost_id > 0"
                        class="u-flex u-flex-align-start"
                    >
                        <small
                            ><span class="card__postedin"
                                ><a :href="'/u/' + card.username"
                                    >{{ card.user_forename }}
                                    {{ card.user_surname }}</a
                                ></span
                            >reposted from</small
                        >
                    </div>
                    <div
                        v-else-if="
                            card.post_network_id === 7 &&
                            card.post_is_draft === true &&
                            card.community_id > 1
                        "
                        class="u-flex u-flex-align-start"
                    >
                        <small>
                            <span class="card__postedin">Draft</span>
                            posting in
                        </small>
                    </div>
                    <div
                        v-else-if="card.post_is_draft === true"
                        class="u-flex u-flex-align-start"
                    >
                        <small>
                            <span class="card__postedin">Draft Post</span>
                        </small>
                    </div>
                    <div
                        v-else-if="card.post_network_id === 7"
                        class="u-flex u-flex-align-start"
                    >
                        <small>
                            <span class="card__postedin">
                                <a :href="'/u/' + card.username">{{ card.user_forename }} {{ card.user_surname }}</a>
                            </span>
                            posted in
                        </small>
                    </div>
                    <div v-else class="card__social-from">
                        <img
                            v-if="card.post_network_id === $simpleStore.Networks.FB"
                            src="/img2.0/icons/facebook.svg" alt="facebook" width="22" height="22">
                        <img
                            v-if="card.post_network_id === $simpleStore.Networks.TW"
                            src="/img2.0/icons/twitter.svg" alt="twitter" width="22" height="22"
                        >
                        <img
                            v-if="card.post_network_id === $simpleStore.Networks.IN"
                            src="/img2.0/icons/instagram.svg" alt="instagram" width="22" height="22"
                        >
                        <img
                            v-if="card.post_network_id === $simpleStore.Networks.YT"
                            src="/img2.0/icons/youtube.png" alt="youtube" width="31" height="22" class="yt"
                        >
                        <template v-for="dupe in card.children">
                            <img
                                v-if="dupe.network_id === $simpleStore.Networks.FB"
                                src="/img2.0/icons/facebook.svg" alt="facebook" width="22" height="22"
                            >
                            <img
                                v-if="dupe.network_id === $simpleStore.Networks.TW"
                                src="/img2.0/icons/twitter.svg" alt="twitter" width="22" height="22"
                            >
                            <img
                                v-if="dupe.network_id === $simpleStore.Networks.IN"
                                src="/img2.0/icons/instagram.svg" alt="instagram" width="22" height="22"
                            >
                            <img
                                v-if="dupe.network_id === $simpleStore.Networks.YT"
                                src="/img2.0/icons/youtube.png" alt="youtube" width="31" height="22" class="yt"
                            >
                        </template>
                    </div>
                </div>
                <!--
                <div v-if="card.post_network_id == $simpleStore.Networks.YT" class="c-video-wrapper" :data-url="'/' + card.community_url + '/' + card.post_id">
                    <div :id="'player-' + card.post_video_id" class="community-player" :data-vid="card.post_video_id"></div>
                </div>
                -->
                <!-- do not display title if is the also the start of the content -->
                <template
                    v-if="
                        card.post_title != null &&
                        card.post_title.indexOf(
                            'This content isn\'t available'
                        ) === -1
                    "
                >
                    <a :href="'/' + card.community_url + '/' + card.dyadey_id">
                        <p
                            class="title"
                            v-if="
                                card.post_title &&
                                card.post_network_id !== $simpleStore.Networks.TW
                            "
                        >
                            <strong v-html="card.post_title"></strong>
                        </p>
                    </a>
                </template>
                <a :href="'/' + card.community_url + '/' + card.dyadey_id">
                    <div
                        class="card__content"
                        v-if="
                            card.post_content &&
                            card.post_network_id !== $simpleStore.Networks.YT
                        "
                        v-html="
                            $simpleStore.highlightHashtags(
                                card.post_content,
                                card.community_url
                            )
                        "
                        v-twitteremoji
                    ></div>
                </a>
                <template v-if="embeddedTweet">
                    <div class="card__tweet">
                        <div class="content">
                            <div class="profile">
                                <img :src="embeddedTweet.profile_image" alt="profile image">
                                <a :href="embeddedTweet.link" target="_blank">@{{ embeddedTweet.user }}</a>
                                &#183;
                                {{ embeddedTweet.created }}
                            </div>
                            <p v-html="embeddedTweet.text" v-twitteremoji></p>
                        </div>
                        <a
                            v-if="embeddedTweet.media != null"
                            :href="embeddedTweet.link"
                            target="_blank"
                            >
                            <img :src="embeddedTweet.media.url" alt="" class="media">
                        </a>
                    </div>
                </template>
                <div class="card__repost">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                        <path d="M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M14 1L11 4L14 7" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 15L12 12L9 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p>Repost</p>
                </div>
                <div class="card__repost" v-if="card.reposted">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                        <path d="M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M14 1L11 4L14 7" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 15L12 12L9 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p>Reposted in 5 communities</p>
                    <div class="card__repost-communities">
                        <div class="card__repost-community">
                            <img src="/img/communities/405/main.jpg" alt="Community 1">
                        </div>
                        <div class="card__repost-community">
                            <img src="/img/communities/7070/main.jpg" alt="Community 2">
                        </div>
                        <div class="card__repost-community">
                            <img src="/img/communities/6204/main.jpg" alt="Community 3">
                        </div>
                        <div
                            class="card__repost-community card__repost-community--more"
                        >
                            +
                        </div>
                    </div>
                </div>
            </div>
            <a
                data-i="4"
                v-else
                class="card__content__wrap"
                :href="'/post/edit/' + card.post_id"
            >
                <div
                    :class="[
                        'card__media',
                        imageDimsClass,
                        card.post_type === 'video' ? 'video' : '',
                    ]"
                    :data-card-type="card.post_type"
                >
                    <img
                        v-if="card.post_type === 'video'"
                        :src="videoPlayIcon"
                        class="play-icon" alt="video will play from article link" width="34" height="39"
                    >
                    <img
                        v-if="card.post_thumbnail"
                        :src="card.post_thumbnail"
                        :alt="card.post_title"
                    >
                </div>
                <!--
                <div v-if="card.post_network_id == $simpleStore.Networks.YT" class="c-video-wrapper" :data-url="'/' + card.community_url + '/' + card.post_id">
                    <div :id="'player-' + card.post_video_id" class="community-player" :data-vid="card.post_video_id"></div>
                </div>
                -->
                <p
                    class="title"
                    v-if="
                        card.post_title &&
                        card.post_network_id !== $simpleStore.Networks.TW
                    "
                >
                    <strong v-html="card.post_title"></strong>
                </p>
                <!--<div class="c-card__content" v-if="card.post_content && card.post_network_id != $simpleStore.Networks.YT" v-html="card.post_content"></div>-->
            </a>
        </div>
        <transition name="fade">
            <div v-if="showDeleteModal" class="cards__menu-delete">
                <div class="o-grid o-grid--center u-bg-white u-border-radius-large">
                    <div class="o-grid__item u-width-full u-a-center u-p-y6 u-p-x6">
                        <template v-if="showDeleteDraft === false">
                            <h4 class="u-color-blue u-text-24 u-semibold u-m-b2">Delete Post</h4>
                            <p class="u-m-b3">Are you sure you want to delete this post?</p>
                        </template>
                        <template v-else>
                            <h4 class="u-color-blue u-text-24 u-semibold u-m-b2">Delete Draft</h4>
                            <p class="u-m-b3">Are you sure you want to delete this post?</p>
                        </template>
                        <div class="u-flex u-flex-justify-center">
                            <button v-if="$root.user !== false && card.user_id === $root.user.id" class="c-btn c-btn--red u-m-r3" @click="deletePost(card.post_id, showDeleteDraft)">Yes delete my {{ item }}</button>
                            <button v-else-if="admin === true" class="c-btn c-btn--red u-m-r3" @click="deleteCommunityPost(card.post_id, card.community_id)">Yes delete community post</button>
                            <button class="c-btn c-btn--red is-outline" @click="closeModal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </article>
</template>

<script>
import MasonryStore from "../configs/masonry-store";
import Menu from "../components/menu/Menu";
import CopyLink from "./CopyLink";
import TwitterEmoji from "@twemoji/api";

export default {
    props: {
        card: Object,
        comments: Boolean
    },
    components: {
        CopyLink,
        Menu,
    },
    data() {
        return {
            // other data
            //lazyLoaded: this.card.lz,
            admin: false,
            closePostcardMenuEvent: 'bus-postcard-',
            item: 'post',
            masonry: MasonryStore,
            message: this.$simpleStore.message,
            menu: this.$parent.$parent.menu,
            postLiked: typeof this.card.liked != 'undefined' && this.card.liked,
            postLikes:
                this.card.likes != null
                    ? this.$simpleStore.formatValue(this.card.likes)
                    : '0',
            showDeleteDraft: false,
            showDeleteModal: false,
            showMenu: false,
            thumb: null,
            uniqueId: `${Math.random().toString(36).substr(2, 9)}-menu-close`,
            videoPlayIcon: '/img2.0/icons/play.svg',
            //initialCards: this.$root.cards.cards
        };
    },
    created() {
        this.closePostcardMenuEvent += this.uniqueId;
        // TODO: maintain some sort of global variable to track all events that can then be called after
        // an outsideClick
        this.$root.$on(this.closePostcardMenuEvent, this.closeMenu);
    },
    beforeDestroy() {
        this.$root.$off(this.closePostcardMenuEvent, this.closeMenu);
    },
    computed: {
        embeddedTweet() {
            let metaData = JSON.parse(this.card.meta_data);
            if (typeof metaData.twitter_embed != 'undefined') {
                let months = [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ];
                let createdDate = new Date(
                    metaData.twitter_embed.created * 1000
                );
                createdDate =
                    createdDate.getDate() +
                    ' ' +
                    months[createdDate.getMonth()] +
                    ' ' +
                    createdDate.getFullYear();
                metaData.twitter_embed.created = createdDate;
                return metaData.twitter_embed;
            }
            return null;
        },
        imageDimsClass() {
            if (this.card.meta_data == null) {
                return null;
            }
            let cssClass = ['portrait'];
            let metaData = JSON.parse(this.card.meta_data);
            let regexDims = /width="(\d+)" height="(\d+)"/g;
            if (typeof metaData.thumbnail_dimensions == "undefined") {
                return cssClass;
            }
            let match = regexDims.exec(metaData.thumbnail_dimensions);
            if (match == null) {
                return cssClass;
            }
            if (parseInt(match[1]) >= parseInt(match[2])) {
                cssClass = ['landscape'];
            }
            return cssClass;
        },
        postImageDimensions() {
            let metaData = JSON.parse(this.card.meta_data);
            if (typeof metaData.thumbnail_dimensions != 'undefined') {
                let regexDims = /width="(\d+)" height="(\d+)"/g;
                let match = regexDims.exec(metaData.thumbnail_dimensions);
                if (match == null) {
                    return {};
                }
                return {
                    width: match[1],
                    height: match[2],
                };
            }
        },
    },
    methods: {
        closeMenu() {
            this.showMenu = false;
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        closeModal() {
            this.showDeleteModal = false;
        },
        join() {
            this.toggleMenu();
            if (this.$root.user === false) {
                this.$simpleStore.showMemberPanel(this);
            }
        },
        deletePost(postId, isDraft) {
            this.$http.post('/api/v1/post/delete', { post_id: postId }).then(
                (response) => {
                    if (response.data.error === false) {
                        if (isDraft) {
                            this.$root.$emit('bus-profile-decrement-drafts');
                            this.$root.$emit('bus-profile-delete-draft', postId);
                        } else {
                            this.$root.$emit('bus-profile-decrement-posts');
                        }
                        this.showDeleteModal = false;
                    }
                },
                (response) => {
                    console.log('Errored:');
                    console.log(response);
                }
            );
        },
        deleteCommunityPost(postId, communityId) {
            if (this.$root.$children[0].admin !== true) {
                return;
            }
            this.$http.post('/post/admindelete', { post_id: postId }).then(
                (response) => {
                    if (response.data.error === false) {
                        //document.querySelector('article[data-pid='' + response.data.post_id + '']').remove();
                        this.$destroy();
                        this.$el.parentNode.removeChild(this.$el);
                        this.$root.$emit('unfade-page');
                        sessionStorage.removeItem('c' + communityId);
                        this.masonry.load();
                        //window.location.reload();
                    }
                },
                (response) => {
                    console.log('Errored:');
                    console.log(response);
                }
            );
        },
        like(confirmed, card) {
            if (this.$root.user !== false) {
                this.$simpleStore.like(this, confirmed, card.dyadey_id, card);
            } else {
                this.$simpleStore.showMemberPanel(this);
            }
        },
        modalDeleteDraft(postId) {
            this.item = 'draft';
            this.showDeleteDraft = true;
            this.showDeleteModal = true;
            this.toggleMenu();
        },
        modalDeletePost(postId) {
            this.item = 'post';
            this.showDeleteDraft = false;
            this.showDeleteModal = true;
        },
        updatePost(ev, card) {
            let postId = card.post_id;
            let networkId = card.post_network_id;
            // only check FB and Insta posts
            if (
                networkId !== this.$simpleStore.Networks.FB &&
                networkId !== this.$simpleStore.Networks.IN
            ) {
                return;
            }
            // don't request more than 8 broken images at a time to avoid overloading server
            if (this.$simpleStore.imageUpdateRequests >= 7) {
                return;
            }
            this.$simpleStore.imageUpdateRequests++;
            this.$http
                .post('/api/v1/posts/update', { postId: postId, networkId: networkId, cacheId: card.cache_id })
                .then(
                    (response) => {
                        this.$simpleStore.imageUpdateRequests--;
                        if (response.data.success === true) {
                            // update media_url in sessionStorage
                            // this.$root.community may not be set if we are on global search page
                            if (typeof this.$root.community != 'undefined') {
                                let updatedId = response.data.post_id;
                                let storageKey;
                                if (this.$root.$children[0].exploreActive === true) {
                                    storageKey = 'explore';
                                } else if (this.$root.$children[0].feedActive === true) {
                                    storageKey = 'feed';
                                } else if (typeof this.$root.community.id != 'undefined') {
                                    storageKey = 'c' + this.$root.community.id;
                                }
                                let commData = JSON.parse(
                                    sessionStorage.getItem(storageKey)
                                );
                                for (
                                    let i = 0;
                                    i < commData[0].cards.length;
                                    i++
                                ) {
                                    let sessionCard = commData[0].cards[i];
                                    if (sessionCard.post_id === updatedId) {
                                        if (
                                            typeof response.data.media_url !=
                                            'undefined'
                                        ) {
                                            sessionCard.post_media =
                                                response.data.media_url;
                                        } else if (
                                            typeof response.data
                                                .post_thumbnail != 'undefined'
                                        ) {
                                            sessionCard.post_thumbnail =
                                                response.data.post_thumbnail;
                                        }
                                        commData[0].cards[i] = sessionCard;
                                        sessionStorage.setItem(
                                            storageKey,
                                            JSON.stringify(commData)
                                        );
                                        break;
                                    }
                                }
                            }
                            if (typeof response.data.media_url != 'undefined') {
                                ev.target.src = response.data.media_url;
                            } else if (
                                typeof response.data.post_thumbnail !=
                                'undefined'
                            ) {
                                ev.target.src = response.data.post_thumbnail;
                            }
                            this.masonry.images();
                        }
                    },
                    (response) => {
                        console.log('Errored:');
                        console.log(response);
                    }
                );
        },
        getPostImage(thumbnail) {
            // do not adjust non local images
            if (thumbnail.match(/^\/media\/images/) == null) {
                return thumbnail;
            }
            let mediaSize = this.$simpleStore.getMediaSize();
            return thumbnail.replace(
                /\.(jpg|jpeg|png|gif|webp)$/,
                '-' + mediaSize + '.$1'
            );
        },
        goTo(location) {
            window.location = location;
        }
    },
    directives: {
        twitteremoji: {
            inserted(el) {
                if (typeof TwitterEmoji === 'object' && typeof TwitterEmoji.parse === 'function') {
                    TwitterEmoji.parse(el, { folder: 'svg', ext: '.svg' });
                }
            },
        },
    },
};
</script>


