var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      class: [
        "card",
        _vm.card.post_network_id === _vm.$simpleStore.Networks.DY ? "dc" : "",
        _vm.showMenu ? "menu-active" : "",
        !_vm.card.post_thumbnail ? "card__no-media" : "",
      ],
      attrs: { "data-did": _vm.card.dyadey_id, "data-cy": "card" },
    },
    [
      _c(
        "div",
        { staticClass: "card__inner" },
        [
          _vm.card.post_is_draft
            ? _c("div", { staticClass: "card__draft" }, [
                _vm.card.draft_only === 1
                  ? _c("p", [_vm._v("Not yet posted")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.card.draft_only === 0
                  ? _c("p", [_vm._v("Version not posted")])
                  : _vm._e(),
                _vm._v(" "),
                _c("a", { attrs: { href: "/post/edit/" + _vm.card.post_id } }, [
                  _vm._v("Edit post"),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.card.post_network_id === _vm.$simpleStore.Networks.DY &&
          _vm.card.post_thumbnail &&
          _vm.card.post_is_draft === false
            ? _c(
                "div",
                {
                  class: [
                    "card__media",
                    _vm.imageDimsClass,
                    _vm.card.post_type === "video" ? "video" : "",
                  ],
                  attrs: { "data-card-type": _vm.card.post_type },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/" +
                          _vm.card.community_url +
                          "/" +
                          _vm.card.dyadey_id,
                      },
                    },
                    [
                      _vm.card.post_type === "video"
                        ? _c("img", {
                            staticClass: "play-icon",
                            attrs: {
                              src: _vm.videoPlayIcon,
                              alt: "video will play from article link",
                              width: "34",
                              height: "39",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.post_thumbnail
                        ? _c("img", {
                            attrs: {
                              src: _vm.card.post_thumbnail,
                              alt: _vm.card.post_title,
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "header",
            [
              _c("div", { staticClass: "card__logo" }, [
                _vm.card.post_network_id === _vm.$simpleStore.Networks.DY &&
                _vm.card.post_show_community_img !== 1 &&
                typeof _vm.$root.community != "undefined" &&
                _vm.$root.community.id > 0
                  ? _c("a", { attrs: { href: "/u/" + _vm.card.user_id } }, [
                      _vm.card.community_id > 0
                        ? _c("img", {
                            staticClass: "logo",
                            attrs: {
                              src: _vm.$simpleStore.cloudinaryUrl(
                                42,
                                42,
                                "img/communities/" +
                                  _vm.card.community_id +
                                  "/" +
                                  _vm.card.community_thumbnail,
                                _vm.$root
                              ),
                              alt: _vm.card.community_name,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "ugc__avatar",
                        attrs: {
                          src: _vm.card.user_avatar,
                          alt: _vm.card.cAlt,
                          width: "24",
                          height: "24",
                        },
                      }),
                    ])
                  : _c(
                      "a",
                      {
                        staticClass: "cards__default-community-icon",
                        attrs: { href: "/" + _vm.card.community_url },
                      },
                      [
                        _vm.card.community_id > 0 &&
                        parseInt(_vm.card.community_id) !==
                          _vm.$root.placeholderCommunityId
                          ? _c("img", {
                              staticClass: "logo",
                              attrs: {
                                src: _vm.$simpleStore.cloudinaryUrl(
                                  42,
                                  42,
                                  "img/communities/" +
                                    _vm.card.community_id +
                                    "/" +
                                    _vm.card.community_thumbnail,
                                  _vm.$root
                                ),
                                alt: _vm.card.community_name,
                                width: "42",
                                height: "42",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: "/img/icons/icon-communities-squared--contained.svg",
                                width: "42",
                                height: "42",
                                alt: "Default Community Image",
                              },
                            }),
                      ]
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    "card__comm-name",
                    _vm.card.post_network_id === _vm.$simpleStore.Networks.DY
                      ? "dc"
                      : "",
                  ],
                },
                [
                  _vm.card.post_parent_id > 0
                    ? _c("div", [_vm._v(_vm._s(_vm.card.post_reposted_from))])
                    : _c(
                        "div",
                        [
                          _vm.card.post_network_id ===
                          _vm.$simpleStore.Networks.DY
                            ? [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.card.user_forename) +
                                      " " +
                                      _vm._s(_vm.card.user_surname)
                                  ),
                                ]),
                                _vm._v(" posted"),
                                _c("br"),
                                _vm._v("\n                            in "),
                                _c("strong", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "/" + _vm.card.community_url,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.card.community_name))]
                                  ),
                                ]),
                              ]
                            : _vm.card.community_id !==
                              _vm.$root.placeholderCommunityId
                            ? [
                                _c("strong", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "/" + _vm.card.community_url,
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.card.community_name))]
                                  ),
                                ]),
                              ]
                            : [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.card.community_name)),
                                ]),
                              ],
                        ],
                        2
                      ),
                  _vm._v(" "),
                  _c(
                    "time",
                    {
                      attrs: {
                        datetime: _vm.card.post_birthday_short,
                        title: "posted on " + _vm.card.post_birthday_full,
                      },
                    },
                    [_vm._v(_vm._s(_vm.card.post_birthday_long))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card__menu", on: { click: _vm.toggleMenu } },
                [_vm._m(0)]
              ),
              _vm._v(" "),
              _c(
                "Menu",
                {
                  attrs: {
                    "show-menu": _vm.showMenu,
                    "close-event": _vm.closePostcardMenuEvent,
                  },
                },
                [
                  _c("div", [
                    _vm.$route.name !== "userPosts" &&
                    _vm.card.post_is_draft === false
                      ? _c(
                          "div",
                          { staticClass: "menu__links" },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "/" +
                                    _vm.card.community_url +
                                    "/" +
                                    _vm.card.dyadey_id,
                                },
                              },
                              [_vm._v("View post")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              { attrs: { href: "/" + _vm.card.community_url } },
                              [_vm._v("View community")]
                            ),
                            _vm._v(" "),
                            typeof _vm.$root.communities == "undefined" ||
                            _vm.$root.communities.includes(
                              _vm.card.community_id
                            ) === false
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.join.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [_vm._v("Join community")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("CopyLink", {
                              attrs: {
                                link:
                                  "/" +
                                  _vm.card.community_url +
                                  "/" +
                                  _vm.card.dyadey_id,
                                "menu-name-event": "bus-postcard-menu-toggle",
                              },
                            }),
                            _vm._v(" "),
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v("Repost to a community"),
                            ]),
                          ],
                          1
                        )
                      : _vm.$route.name === "userPosts" &&
                        _vm.card.post_is_draft === false
                      ? _c("div", { staticClass: "menu__links" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "/post/edit/" + _vm.card.post_id },
                            },
                            [_vm._v("Edit post")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" +
                                  _vm.card.community_url +
                                  "/" +
                                  _vm.card.dyadey_id,
                              },
                            },
                            [_vm._v("View post")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            { attrs: { href: "/" + _vm.card.community_url } },
                            [_vm._v("View community")]
                          ),
                        ])
                      : _vm.card.post_is_draft === true
                      ? _c("div", { staticClass: "menu__links" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "/post/edit/" + _vm.card.post_id },
                            },
                            [_vm._v("Edit draft")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.modalDeleteDraft(_vm.card.post_id)
                                },
                              },
                            },
                            [_vm._v("Delete draft")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.toggleMenu.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$route.name !== "userPosts" &&
                    _vm.card.post_is_draft === false
                      ? _c("div", { staticClass: "menu__source" }, [
                          _c("p", [_vm._v("View original source")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "menu__socials" },
                            [
                              _vm.card.post_network_id ===
                              _vm.$simpleStore.Networks.FB
                                ? _c("div", { staticClass: "menu__social" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.card.post_uri,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/img2.0/icons/facebook.svg",
                                            alt: "facebook",
                                            width: "25",
                                            height: "25",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.card.post_network_id ===
                              _vm.$simpleStore.Networks.IN
                                ? _c("div", { staticClass: "menu__social" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.card.post_source,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/img2.0/icons/instagram.svg",
                                            alt: "instagram",
                                            width: "25",
                                            height: "25",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.card.post_network_id ===
                              _vm.$simpleStore.Networks.YT
                                ? _c("div", { staticClass: "menu__social" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.card.post_uri,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/img2.0/icons/youtube.svg",
                                            alt: "facebook",
                                            width: "25",
                                            height: "25",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.card.post_network_id ===
                              _vm.$simpleStore.Networks.TW
                                ? _c("div", { staticClass: "menu__social" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.card.post_uri,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/img2.0/icons/twitter.svg",
                                            alt: "facebook",
                                            width: "25",
                                            height: "25",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.card.children, function (dupe) {
                                return [
                                  dupe.network_id ===
                                  _vm.$simpleStore.Networks.FB
                                    ? _c(
                                        "div",
                                        { staticClass: "menu__social" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: dupe.uri,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: "/img2.0/icons/instagram.svg",
                                                  alt: "instagram",
                                                  width: "25",
                                                  height: "25",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  dupe.network_id ===
                                  _vm.$simpleStore.Networks.IN
                                    ? _c(
                                        "div",
                                        { staticClass: "menu__social" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: dupe.source,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: "/img2.0/icons/instagram.svg",
                                                  alt: "instagram",
                                                  width: "25",
                                                  height: "25",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  dupe.network_id ===
                                  _vm.$simpleStore.Networks.YT
                                    ? _c(
                                        "div",
                                        { staticClass: "menu__social" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: dupe.uri,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: "/img2.0/icons/youtube.svg",
                                                  alt: "facebook",
                                                  width: "25",
                                                  height: "25",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  dupe.network_id ===
                                  _vm.$simpleStore.Networks.TW
                                    ? _c(
                                        "div",
                                        { staticClass: "menu__social" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: dupe.uri,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: "/img2.0/icons/twitter.svg",
                                                  alt: "facebook",
                                                  width: "25",
                                                  height: "25",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.card.post_network_id === _vm.$simpleStore.Networks.DY &&
          _vm.card.post_is_draft === false
            ? [
                _c("p", { staticClass: "title" }, [
                  _c("strong", {
                    domProps: { innerHTML: _vm._s(_vm.card.post_title) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-ctas-wrap" }, [
                  _vm.card.post_is_draft !== true
                    ? _c(
                        "div",
                        { staticClass: "card-ctas" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "card__likes",
                              class: { liked: _vm.postLiked > 0 },
                              attrs: { "data-cy": "like-post" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.like(false, _vm.card)
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "21",
                                    viewBox: "0 0 24 21",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M11.728 19.8376C11.29 19.5766 1 13.3538 1 6.76474C1 3.58612 3.62739 1 6.85673 1C9.02896 1 10.9907 2.18834 12 4.00725C13.0093 2.18834 14.971 1 17.1433 1C20.3726 1 23 3.58612 23 6.76474C23 13.3538 12.71 19.5755 12.272 19.8376L12 20L11.728 19.8376Z",
                                      stroke: "black",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.card.likes))]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.comments !== false
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "card__comments",
                                    on: {
                                      click: function ($event) {
                                        return _vm.goTo(
                                          "/" +
                                            _vm.card.community_url +
                                            "/" +
                                            _vm.card.dyadey_id +
                                            "#comments"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/img2.0/icons/comment.svg",
                                        width: "20",
                                        height: "19",
                                        alt: "comments",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.card.comments)),
                                    ]),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "slider-nav" }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card__repost" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "22",
                        height: "16",
                        viewBox: "0 0 22 16",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11",
                          stroke: "black",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M14 1L11 4L14 7",
                          stroke: "black",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M9 15L12 12L9 9",
                          stroke: "black",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("Repost")]),
                ]),
              ]
            : _vm.card.post_network_id !== _vm.$simpleStore.Networks.IN &&
              _vm.card.post_network_id !== _vm.$simpleStore.Networks.YT &&
              !_vm.card.post_thumbnail &&
              _vm.card.post_is_draft === false
            ? [
                _vm.card.post_title != null &&
                _vm.card.post_content.indexOf(_vm.card.post_title) !== 0 &&
                _vm.card.post_title !== "Timeline Photos" &&
                _vm.card.post_title.indexOf("This content isn't available") ===
                  -1
                  ? [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/" +
                              _vm.card.community_url +
                              "/" +
                              _vm.card.dyadey_id,
                          },
                        },
                        [
                          _vm.card.post_title &&
                          _vm.card.post_network_id !==
                            _vm.$simpleStore.Networks.TW
                            ? _c("p", { staticClass: "title" }, [
                                _c("strong", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.card.post_title),
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/" + _vm.card.community_url + "/" + _vm.card.dyadey_id,
                    },
                  },
                  [
                    _vm.card.post_content &&
                    _vm.card.post_network_id !== _vm.$simpleStore.Networks.YT
                      ? _c("div", {
                          directives: [
                            { name: "twitteremoji", rawName: "v-twitteremoji" },
                          ],
                          staticClass: "card__content",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$simpleStore.highlightHashtags(
                                _vm.card.post_content,
                                _vm.card.community_url
                              )
                            ),
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.embeddedTweet
                  ? [
                      _c("div", { staticClass: "card__tweet" }, [
                        _c("div", { staticClass: "content" }, [
                          _c("div", { staticClass: "profile" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.embeddedTweet.profile_image,
                                alt: "profile image",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.embeddedTweet.link,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("@" + _vm._s(_vm.embeddedTweet.user))]
                            ),
                            _vm._v(
                              "\n                                ·\n                                " +
                                _vm._s(_vm.embeddedTweet.created) +
                                "\n                            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            directives: [
                              {
                                name: "twitteremoji",
                                rawName: "v-twitteremoji",
                              },
                            ],
                            domProps: {
                              innerHTML: _vm._s(_vm.embeddedTweet.text),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.embeddedTweet.media != null
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.embeddedTweet.link,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "media",
                                  attrs: {
                                    src: _vm.embeddedTweet.media.url,
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "card-ctas-wrap" }, [
                  _vm.card.post_is_draft !== true
                    ? _c("div", { staticClass: "card-ctas" }, [
                        _c(
                          "button",
                          {
                            staticClass: "card__likes",
                            class: { liked: _vm.postLiked > 0 },
                            attrs: { "data-cy": "like-post" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.like(false, _vm.card)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/img2.0/icons/like.svg",
                                width: "22",
                                height: "19",
                                alt: "like",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "24",
                                  height: "21",
                                  viewBox: "0 0 24 21",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M11.728 19.8376C11.29 19.5766 1 13.3538 1 6.76474C1 3.58612 3.62739 1 6.85673 1C9.02896 1 10.9907 2.18834 12 4.00725C13.0093 2.18834 14.971 1 17.1433 1C20.3726 1 23 3.58612 23 6.76474C23 13.3538 12.71 19.5755 12.272 19.8376L12 20L11.728 19.8376Z",
                                    stroke: "black",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.card.likes))]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "card__comments",
                            on: {
                              click: function ($event) {
                                return _vm.goTo(
                                  "/" +
                                    _vm.card.community_url +
                                    "/" +
                                    _vm.card.dyadey_id +
                                    "#comments"
                                )
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/img2.0/icons/comment.svg",
                                width: "20",
                                height: "19",
                                alt: "comments",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.card.comments))]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "slider-nav" }),
                  _vm._v(" "),
                  _vm.card.repost_id > 0
                    ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                        _c("small", [
                          _c("span", { staticClass: "card__postedin" }, [
                            _c(
                              "a",
                              { attrs: { href: "/u/" + _vm.card.username } },
                              [
                                _vm._v(
                                  _vm._s(_vm.card.user_forename) +
                                    " " +
                                    _vm._s(_vm.card.user_surname)
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(
                            "\n                            reposted from\n                        "
                          ),
                        ]),
                      ])
                    : _vm.card.post_network_id === 7 &&
                      _vm.card.post_is_draft === true &&
                      _vm.card.community_id > 1
                    ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                        _vm._m(1),
                      ])
                    : _vm.card.post_is_draft === true
                    ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                        _vm._m(2),
                      ])
                    : _vm.card.post_network_id === 7
                    ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                        _c("small", [
                          _c("span", { staticClass: "card__postedin" }, [
                            _c(
                              "a",
                              { attrs: { href: "/u/" + _vm.card.username } },
                              [
                                _vm._v(
                                  _vm._s(_vm.card.user_forename) +
                                    " " +
                                    _vm._s(_vm.card.user_surname)
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(
                            "\n                            posted in\n                        "
                          ),
                        ]),
                      ])
                    : _c(
                        "div",
                        { staticClass: "card__social-from" },
                        [
                          _vm.card.post_network_id ===
                          _vm.$simpleStore.Networks.FB
                            ? _c("img", {
                                attrs: {
                                  src: "/img2.0/icons/facebook.svg",
                                  alt: "facebook",
                                  width: "22",
                                  height: "22",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.post_network_id ===
                          _vm.$simpleStore.Networks.TW
                            ? _c("img", {
                                attrs: {
                                  src: "/img2.0/icons/twitter.svg",
                                  alt: "twitter",
                                  width: "22",
                                  height: "22",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.card.children, function (dupe) {
                            return [
                              dupe.network_id === _vm.$simpleStore.Networks.FB
                                ? _c("img", {
                                    attrs: {
                                      src: "/img2.0/icons/facebook.svg",
                                      alt: "facebook",
                                      width: "22",
                                      height: "22",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              dupe.network_id === _vm.$simpleStore.Networks.TW
                                ? _c("img", {
                                    attrs: {
                                      src: "/img2.0/icons/twitter.svg",
                                      alt: "twitter",
                                      width: "22",
                                      height: "22",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                ]),
              ]
            : _vm.card.post_is_draft === false
            ? _c(
                "div",
                {
                  staticClass: "card__content__wrap",
                  attrs: { "data-i": "3" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/" +
                          _vm.card.community_url +
                          "/" +
                          _vm.card.dyadey_id,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            "card__media",
                            _vm.imageDimsClass,
                            _vm.card.post_type.indexOf("video") !== -1
                              ? "video"
                              : "",
                          ],
                          attrs: { "data-card-type": _vm.card.post_type },
                        },
                        [
                          _vm.card.post_network_id !==
                            _vm.$simpleStore.Networks.IN &&
                          _vm.card.post_thumbnail
                            ? _c(
                                "img",
                                _vm._b(
                                  {
                                    attrs: {
                                      src: _vm.getPostImage(
                                        _vm.card.post_thumbnail
                                      ),
                                      alt: _vm.card.post_title,
                                    },
                                    on: {
                                      error: (event) =>
                                        _vm.updatePost(event, _vm.card),
                                    },
                                  },
                                  "img",
                                  _vm.postImageDimensions,
                                  false
                                )
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.post_network_id ===
                            _vm.$simpleStore.Networks.IN &&
                          _vm.card.post_type !== "video" &&
                          _vm.card.post_thumbnail
                            ? _c(
                                "img",
                                _vm._b(
                                  {
                                    attrs: {
                                      src: _vm.getPostImage(
                                        _vm.card.post_thumbnail
                                      ),
                                      alt: _vm.card.community_name,
                                    },
                                    on: {
                                      error: (event) =>
                                        _vm.updatePost(event, _vm.card),
                                    },
                                  },
                                  "img",
                                  _vm.postImageDimensions,
                                  false
                                )
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.post_network_id ===
                            _vm.$simpleStore.Networks.IN &&
                          _vm.card.post_type === "video"
                            ? _c(
                                "img",
                                _vm._b(
                                  {
                                    attrs: {
                                      src:
                                        _vm.card.post_thumbnail == null ||
                                        _vm.card.post_thumbnail.indexOf(
                                          "http"
                                        ) === -1
                                          ? "https://dyadey.com" +
                                            _vm.card.post_thumbnail
                                          : _vm.card.post_thumbnail,
                                      alt: _vm.card.community_name,
                                    },
                                    on: {
                                      error: (event) =>
                                        _vm.updatePost(event, _vm.card),
                                    },
                                  },
                                  "img",
                                  _vm.postImageDimensions,
                                  false
                                )
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.post_type.indexOf("video") !== -1
                            ? _c("img", {
                                staticClass: "play-icon",
                                attrs: {
                                  src: _vm.videoPlayIcon,
                                  alt: "video will play from article link",
                                  width: "34",
                                  height: "39",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.card.post_has_slides
                            ? _c("div", { staticClass: "slider-arrows" }, [
                                _c("img", {
                                  staticClass: "prev",
                                  attrs: {
                                    src: "/img2.0/icons/slide-arrow.svg",
                                    width: "36px",
                                    height: "36px",
                                    alt: "slide arrow",
                                  },
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "next",
                                  attrs: {
                                    src: "/img2.0/icons/slide-arrow.svg",
                                    width: "36px",
                                    height: "36px",
                                    alt: "slide arrow",
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._m(3),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-ctas-wrap" }, [
                    _vm.card.post_is_draft !== true
                      ? _c("div", { staticClass: "card-ctas" }, [
                          _c(
                            "button",
                            {
                              staticClass: "card__likes",
                              class: { liked: _vm.postLiked > 0 },
                              attrs: { "data-cy": "like-post" },
                              on: {
                                click: function ($event) {
                                  return _vm.like(false, _vm.card)
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "24",
                                    height: "21",
                                    viewBox: "0 0 24 21",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M11.728 19.8376C11.29 19.5766 1 13.3538 1 6.76474C1 3.58612 3.62739 1 6.85673 1C9.02896 1 10.9907 2.18834 12 4.00725C13.0093 2.18834 14.971 1 17.1433 1C20.3726 1 23 3.58612 23 6.76474C23 13.3538 12.71 19.5755 12.272 19.8376L12 20L11.728 19.8376Z",
                                      stroke: "black",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.card.likes))]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "card__comments",
                              on: {
                                click: function ($event) {
                                  return _vm.goTo(
                                    "/" +
                                      _vm.card.community_url +
                                      "/" +
                                      _vm.card.dyadey_id +
                                      "#comments"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "22",
                                    height: "21",
                                    viewBox: "0 0 22 21",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M21 20L19.4891 14.4021C20.4468 12.339 20.6181 10.0081 19.9719 7.83187C19.3256 5.65568 17.9047 3.77825 15.9665 2.53997C14.0284 1.30168 11.7014 0.784484 9.40743 1.08212C7.11341 1.37975 5.00419 2.47254 3.46214 4.16235C1.92008 5.85216 1.04725 8.02716 1.00186 10.2931C0.956479 12.559 1.74155 14.7659 3.21475 16.5136C4.68796 18.2614 6.7518 19.4343 9.03214 19.8198C11.3125 20.2053 13.6584 19.7779 15.6446 18.615L21 20Z",
                                      stroke: "black",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.card.comments))]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.repost_id > 0
                      ? _c(
                          "div",
                          { staticClass: "u-flex u-flex-align-start" },
                          [
                            _c("small", [
                              _c("span", { staticClass: "card__postedin" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "/u/" + _vm.card.username },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.card.user_forename) +
                                        "\n                                    " +
                                        _vm._s(_vm.card.user_surname)
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v("reposted from"),
                            ]),
                          ]
                        )
                      : _vm.card.post_network_id === 7 &&
                        _vm.card.post_is_draft === true &&
                        _vm.card.community_id > 1
                      ? _c(
                          "div",
                          { staticClass: "u-flex u-flex-align-start" },
                          [_vm._m(4)]
                        )
                      : _vm.card.post_is_draft === true
                      ? _c(
                          "div",
                          { staticClass: "u-flex u-flex-align-start" },
                          [_vm._m(5)]
                        )
                      : _vm.card.post_network_id === 7
                      ? _c(
                          "div",
                          { staticClass: "u-flex u-flex-align-start" },
                          [
                            _c("small", [
                              _c("span", { staticClass: "card__postedin" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "/u/" + _vm.card.username },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.card.user_forename) +
                                        " " +
                                        _vm._s(_vm.card.user_surname)
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(
                                "\n                            posted in\n                        "
                              ),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "card__social-from" },
                          [
                            _vm.card.post_network_id ===
                            _vm.$simpleStore.Networks.FB
                              ? _c("img", {
                                  attrs: {
                                    src: "/img2.0/icons/facebook.svg",
                                    alt: "facebook",
                                    width: "22",
                                    height: "22",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.card.post_network_id ===
                            _vm.$simpleStore.Networks.TW
                              ? _c("img", {
                                  attrs: {
                                    src: "/img2.0/icons/twitter.svg",
                                    alt: "twitter",
                                    width: "22",
                                    height: "22",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.card.post_network_id ===
                            _vm.$simpleStore.Networks.IN
                              ? _c("img", {
                                  attrs: {
                                    src: "/img2.0/icons/instagram.svg",
                                    alt: "instagram",
                                    width: "22",
                                    height: "22",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.card.post_network_id ===
                            _vm.$simpleStore.Networks.YT
                              ? _c("img", {
                                  staticClass: "yt",
                                  attrs: {
                                    src: "/img2.0/icons/youtube.png",
                                    alt: "youtube",
                                    width: "31",
                                    height: "22",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.card.children, function (dupe) {
                              return [
                                dupe.network_id === _vm.$simpleStore.Networks.FB
                                  ? _c("img", {
                                      attrs: {
                                        src: "/img2.0/icons/facebook.svg",
                                        alt: "facebook",
                                        width: "22",
                                        height: "22",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                dupe.network_id === _vm.$simpleStore.Networks.TW
                                  ? _c("img", {
                                      attrs: {
                                        src: "/img2.0/icons/twitter.svg",
                                        alt: "twitter",
                                        width: "22",
                                        height: "22",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                dupe.network_id === _vm.$simpleStore.Networks.IN
                                  ? _c("img", {
                                      attrs: {
                                        src: "/img2.0/icons/instagram.svg",
                                        alt: "instagram",
                                        width: "22",
                                        height: "22",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                dupe.network_id === _vm.$simpleStore.Networks.YT
                                  ? _c("img", {
                                      staticClass: "yt",
                                      attrs: {
                                        src: "/img2.0/icons/youtube.png",
                                        alt: "youtube",
                                        width: "31",
                                        height: "22",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            }),
                          ],
                          2
                        ),
                  ]),
                  _vm._v(" "),
                  _vm.card.post_title != null &&
                  _vm.card.post_title.indexOf(
                    "This content isn't available"
                  ) === -1
                    ? [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/" +
                                _vm.card.community_url +
                                "/" +
                                _vm.card.dyadey_id,
                            },
                          },
                          [
                            _vm.card.post_title &&
                            _vm.card.post_network_id !==
                              _vm.$simpleStore.Networks.TW
                              ? _c("p", { staticClass: "title" }, [
                                  _c("strong", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.card.post_title),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/" +
                          _vm.card.community_url +
                          "/" +
                          _vm.card.dyadey_id,
                      },
                    },
                    [
                      _vm.card.post_content &&
                      _vm.card.post_network_id !== _vm.$simpleStore.Networks.YT
                        ? _c("div", {
                            directives: [
                              {
                                name: "twitteremoji",
                                rawName: "v-twitteremoji",
                              },
                            ],
                            staticClass: "card__content",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$simpleStore.highlightHashtags(
                                  _vm.card.post_content,
                                  _vm.card.community_url
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.embeddedTweet
                    ? [
                        _c("div", { staticClass: "card__tweet" }, [
                          _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "profile" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.embeddedTweet.profile_image,
                                  alt: "profile image",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.embeddedTweet.link,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("@" + _vm._s(_vm.embeddedTweet.user))]
                              ),
                              _vm._v(
                                "\n                                ·\n                                " +
                                  _vm._s(_vm.embeddedTweet.created) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", {
                              directives: [
                                {
                                  name: "twitteremoji",
                                  rawName: "v-twitteremoji",
                                },
                              ],
                              domProps: {
                                innerHTML: _vm._s(_vm.embeddedTweet.text),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _vm.embeddedTweet.media != null
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.embeddedTweet.link,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "media",
                                    attrs: {
                                      src: _vm.embeddedTweet.media.url,
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "card__repost" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "22",
                          height: "16",
                          viewBox: "0 0 22 16",
                          fill: "none",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11",
                            stroke: "black",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M14 1L11 4L14 7",
                            stroke: "black",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            d: "M9 15L12 12L9 9",
                            stroke: "black",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v("Repost")]),
                  ]),
                  _vm._v(" "),
                  _vm.card.reposted
                    ? _c("div", { staticClass: "card__repost" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "22",
                              height: "16",
                              viewBox: "0 0 22 16",
                              fill: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1 4V6C1 9.31371 3.68629 12 7 12H11M21 12V10C21 6.68629 18.3137 4 15 4H11",
                                stroke: "black",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M14 1L11 4L14 7",
                                stroke: "black",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M9 15L12 12L9 9",
                                stroke: "black",
                                "stroke-width": "1.5",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Reposted in 5 communities")]),
                        _vm._v(" "),
                        _vm._m(6),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _c(
                "a",
                {
                  staticClass: "card__content__wrap",
                  attrs: {
                    "data-i": "4",
                    href: "/post/edit/" + _vm.card.post_id,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "card__media",
                        _vm.imageDimsClass,
                        _vm.card.post_type === "video" ? "video" : "",
                      ],
                      attrs: { "data-card-type": _vm.card.post_type },
                    },
                    [
                      _vm.card.post_type === "video"
                        ? _c("img", {
                            staticClass: "play-icon",
                            attrs: {
                              src: _vm.videoPlayIcon,
                              alt: "video will play from article link",
                              width: "34",
                              height: "39",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.card.post_thumbnail
                        ? _c("img", {
                            attrs: {
                              src: _vm.card.post_thumbnail,
                              alt: _vm.card.post_title,
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.card.post_title &&
                  _vm.card.post_network_id !== _vm.$simpleStore.Networks.TW
                    ? _c("p", { staticClass: "title" }, [
                        _c("strong", {
                          domProps: { innerHTML: _vm._s(_vm.card.post_title) },
                        }),
                      ])
                    : _vm._e(),
                ]
              ),
        ],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showDeleteModal
          ? _c("div", { staticClass: "cards__menu-delete" }, [
              _c(
                "div",
                {
                  staticClass:
                    "o-grid o-grid--center u-bg-white u-border-radius-large",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "o-grid__item u-width-full u-a-center u-p-y6 u-p-x6",
                    },
                    [
                      _vm.showDeleteDraft === false
                        ? [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "u-color-blue u-text-24 u-semibold u-m-b2",
                              },
                              [_vm._v("Delete Post")]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "u-m-b3" }, [
                              _vm._v(
                                "Are you sure you want to delete this post?"
                              ),
                            ]),
                          ]
                        : [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "u-color-blue u-text-24 u-semibold u-m-b2",
                              },
                              [_vm._v("Delete Draft")]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "u-m-b3" }, [
                              _vm._v(
                                "Are you sure you want to delete this post?"
                              ),
                            ]),
                          ],
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "u-flex u-flex-justify-center" },
                        [
                          _vm.$root.user !== false &&
                          _vm.card.user_id === _vm.$root.user.id
                            ? _c(
                                "button",
                                {
                                  staticClass: "c-btn c-btn--red u-m-r3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deletePost(
                                        _vm.card.post_id,
                                        _vm.showDeleteDraft
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Yes delete my " + _vm._s(_vm.item))]
                              )
                            : _vm.admin === true
                            ? _c(
                                "button",
                                {
                                  staticClass: "c-btn c-btn--red u-m-r3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCommunityPost(
                                        _vm.card.post_id,
                                        _vm.card.community_id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Yes delete community post")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "c-btn c-btn--red is-outline",
                              on: { click: _vm.closeModal },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "dots" }, [
      _c("div", { staticClass: "dot" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("span", { staticClass: "card__postedin" }, [_vm._v("Draft")]),
      _vm._v(
        "\n                            posting in\n                        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("span", { staticClass: "card__postedin" }, [_vm._v("Draft Post")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slider-nav" }, [
      _c("img", {
        attrs: {
          src: "/img2.0/icons/slide.svg",
          width: "6",
          height: "2",
          alt: "slide active",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/img2.0/icons/slide-active.svg",
          width: "6",
          height: "2",
          alt: "slide active",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("span", { staticClass: "card__postedin" }, [_vm._v("Draft")]),
      _vm._v(
        "\n                            posting in\n                        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("span", { staticClass: "card__postedin" }, [_vm._v("Draft Post")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card__repost-communities" }, [
      _c("div", { staticClass: "card__repost-community" }, [
        _c("img", {
          attrs: { src: "/img/communities/405/main.jpg", alt: "Community 1" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card__repost-community" }, [
        _c("img", {
          attrs: { src: "/img/communities/7070/main.jpg", alt: "Community 2" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card__repost-community" }, [
        _c("img", {
          attrs: { src: "/img/communities/6204/main.jpg", alt: "Community 3" },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card__repost-community card__repost-community--more" },
        [_vm._v("\n                            +\n                        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }